<template>
  <section>
    <!-- breadcrumb -->
    <div class="breadcrumb-top">
      <b-breadcrumb class="">
        <b-breadcrumb-item :to="{ name: 'dashboard' }">
          <feather-icon
            icon="HomeIcon"
            size="16"
          />
        </b-breadcrumb-item>
        <b-breadcrumb-item>
          {{ $t('Master Data') }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active >
          {{ $t('Master Code Data') }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>

    <b-row>
      <b-col lg="3" class="ll-padding">
        <b-card class="ll-card1" :style="style1">
          <b-card-body>
            <b-form-input v-model="codeName" placeholder="Search master data name"/>
            <p></p>
            <vxe-table
              show-overflow
              highlight-hover-row
              row-key
              :show-header="false"
              :data="treeList"
              :checkbox-config="{labelField: 'codeName'}"
              :tree-config="{children: 'children', accordion: true, line: true, iconOpen: 'fa fa-caret-down', iconClose: 'fa fa-caret-right'}"
              v-permission="[170]"
              v-if="false"
            >
              <vxe-table-column type="checkbox" tree-node></vxe-table-column>
            </vxe-table>
            <div v-loading="isLoading" class="comp-tree">
            <!-- <el-button class="comp-tr-top"
              type="primary"
              size="small"
              @click="handleAddTop">Add Parent Code</el-button> -->
              <b-button
                variant="info"
                class="ll-addTopBtn"
                @click="handleAddTop"
              >
                <span>{{ $t('Add Parent Code') }}</span>
              </b-button>
            <!-- tree -->
            <el-tree ref="SlotTree"
              :data="treeList"
              :props="defaultProps"
              :expand-on-click-node="false"
              highlight-current
              :node-key="NODE_KEY"
              class="ll-tree"
              :style="style3"
            >
              <div class="comp-tr-node" slot-scope="{ node, data }">
                <!-- 编辑状态 -->
                <template v-if="node.isEdit">
                  <el-input v-model="data.codeName"
                    autofocus
                    size="mini"
                    :ref="'slotTreeInput'+data[NODE_KEY]"
                    @blur.stop="handleInput(node, data)"
                    @keyup.enter.native="handleInput(node, data)"></el-input>
                </template>

                <!-- 非编辑状态 -->
                <template v-else>
                  <!-- 名称： 新增节点增加class（is-new） -->
                  <span :class="[data[NODE_KEY] < NODE_ID_START ? 'is-new' : '', 'comp-tr-node--name']">
                    {{ node.label }}
                  </span>

                  <!-- 按钮 -->
                  <span class="comp-tr-node--btns">
                    <!-- 新增 -->
                    <el-button icon="el-icon-plus"
                      size="mini"
                      circle
                      type="primary"
                      @click="handleAdd(node, data)"
                    ></el-button>

                    <!-- 编辑 -->
                    <el-button icon="el-icon-edit"
                      size="mini"
                      circle
                      type="info"
                      @click="handleEdit(node, data)"></el-button>

                    <!-- 删除 -->
                    <el-button icon="el-icon-delete"
                      size="mini"
                      circle
                      type="danger"
                      @click="handleDelete(node, data)"></el-button>
                  </span>
                </template>
                <!-- modal add-->
                <b-modal
                  id="modal-add"
                  cancel-variant="outline-secondary"
                  centered
                  title="Add node"
                  v-model="showAddModal"
                >
                  <validation-observer ref="rules">
                  <b-form>
                    <b-form-group
                    label="Code Category"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Code Category"
                        rules="required"
                      >
                        <v-select
                          v-model="addInfo.codeCategory"
                          :options="categoryList"
                          @input="selCategory"
                          placeholder=""
                        ></v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Code Name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Code Name"
                        rules="required"
                      >
                        <b-form-input v-model="addInfo.codeName"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Code Descrption"
                    >
                      <b-form-input v-model="addInfo.codeDescription"/>
                    </b-form-group>
                  </b-form>
                  </validation-observer>
                  <template #modal-footer>
                    <b-button variant="outline-secondary"
                      @click="cancelAdd"
                    >
                      {{ $t('Close') }}
                    </b-button>
                    <b-button variant="primary"
                      @click.prevent="validationForm(node)"
                      :disabled="isOk"
                    >
                      {{ $t('Submit') }}
                    </b-button>
                  </template>
                </b-modal>
              </div>
            </el-tree>
          </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="9">
        <b-card class="ll-card1" :style="style1">
          <b-card-body>
            <b-table :items="items" :fields="fields"
              :borderless='false' :hover="false"
              :sticky-header="true"
              class="ll-tableHei"
              :style="style2"
              :busy="isBusy"
            >
              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(isEnabled)="scope">
                <b-form-checkbox
                  v-model="scope.value"
                  :class="scope.value ? 'custom-control-info': 'custom-control-secondary'"
                  disabled="disabled"
                >
                </b-form-checkbox>
              </template>
            </b-table>
            <div class="d-flex justify-content-between flex-wrap ll-p">
              <div class="d-flex align-items-center mb-0 mr-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="size"
                  :options="pageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ totalSize }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="page"
                  :total-rows="totalSize"
                  :per-page="size"
                  first-number
                  last-number
                  class="mb-0 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- modal addTop-->
    <b-modal
      id="modal-addTop"
      cancel-variant="outline-secondary"
      centered
      title="Add node"
      v-model="showAddTopModal"
    >
      <validation-observer ref="rules">
      <b-form>
        <b-form-group
        label="Code Category"
        >
          <validation-provider
            #default="{ errors }"
            name="Code Category"
            rules="required"
          >
            <v-select
              v-model="addInfo.codeCategory"
              :options="categoryList"
              @input="selCategory1"
              placeholder=""
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Code Name"
        >
          <validation-provider
            #default="{ errors }"
            name="Code Name"
            rules="required"
          >
            <b-form-input v-model="addInfo.codeName"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Code Descrption"
        >
          <b-form-input v-model="addInfo.codeDescription"/>
        </b-form-group>
      </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="outline-secondary"
          @click="cancelAdd"
        >
          {{ $t('Close') }}
        </b-button>
        <b-button variant="primary"
          @click.prevent="validationForm"
        >
          {{ $t('Submit') }}
        </b-button>
      </template>
    </b-modal>
    <!-- modal edit-->
    <b-modal
      id="modal-edit"
      cancel-variant="outline-secondary"
      centered
      title="edit node"
      v-model="showEditModal"
    >
      <validation-observer ref="rules1">
      <b-form>
        <b-form-group
         label="Code Category"
        >
          <validation-provider
            #default="{ errors }"
            name="Code Category"
            rules="required"
          >
            <v-select
              v-model="editInfo.codeCategory"
              :options="categoryList"
              @input="selCategory"
              placeholder=""
            ></v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Code Name"
        >
          <validation-provider
            #default="{ errors }"
            name="Code Name"
            rules="required"
          >
            <b-form-input v-model="editInfo.codeName"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Code Descrption"
        >
          <b-form-input v-model="editInfo.codeDescription"/>
        </b-form-group>
        <b-form-group
          label="Status"
        >
          <b-form-checkbox
            v-model="editInfo.isEnabled"
            :class="editInfo.isEnabled ? 'custom-control-info': 'custom-control-secondary'"
          >
          </b-form-checkbox>
        </b-form-group>
      </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="outline-secondary"
          @click="cancelEdit"
        >
          {{ $t('Close') }}
        </b-button>
        <b-button variant="primary"
          @click.prevent="validationForm1"
          :disabled="isOk"
        >
          {{ $t('Submit') }}
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BButton,
  BTooltip,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BSidebar,
  VBToggle,
  BTable,
  BInputGroupPrepend,
  BInputGroup,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormRadio,
  BFormCheckbox,
  BModal, VBModal,
  BForm, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import permission from '@core/directives/permission/index.js'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    BTooltip,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BSidebar,
    vSelect,
    BTable,
    BInputGroupPrepend,
    BInputGroup,
    BBreadcrumb,
    BBreadcrumbItem,
    AppCollapse,
    AppCollapseItem,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BModal,
    BForm,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
    permission,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      email,
      windowHeight: document.documentElement.clientHeight,
      cardHei: document.documentElement.clientHeight *0.82,
      items: [],
      treeList: [
        // { name : 'Company Type', children: [
        //   { name: 'Customer' }
        // ] }
      ],
      checkRowKeysList: [],
      condition: {
        page: '1',
        size: '15',
        orderBy: 'id',
        orderMole: 0,
        startTime: '',
        dataType: '',
        codeName: '',
      },
      addInfo: {
        codeCategory: null,
        codeDescription: null,
        // codeId: null,
        codeName: null,
        parentCode: null,
      },
      editInfo: {
        codeCategory: null,
        codeDescription: null,
        codeId: null,
        codeName: null,
        parentCode: null,
      },
      tableInfo: [],
      dateConfig: {
        dateFormat: 'Y-m-d H:i:s',
        defaultDate: new Date(),
        enableTime: true, // 启用时间选择器
      },
      codeName: '',
      isLoading: false,// 是否加载
			setTree: [],// tree数据
			NODE_KEY: 'id',// id对应字段
			MAX_LEVEL: 3,// 设定最大层级
			NODE_ID_START: 0,// 新增节点id，逐次递减
			startId: null,
			defaultProps: {// 默认设置
				children: 'children',
				label: 'codeName'
			},
			initParam: {// 新增参数
				codeName: '新增节点',
				pid: 0,
				children: []
			},
      categoryList: ['data', 'type'],
      showAddModal: false,
      showAddTopModal: false,
      showEditModal: false,
      fields: [
        { key: 'id', label: this.$t('Code ID') },
        { key: 'codeCategory', label: this.$t('Code Category') },
        { key: 'codeName', label: this.$t('Code Name') },
        { key: 'codeDescription', label: this.$t('Code Description') },
        { key: 'parentCode', label: this.$t('Parent Code') },
        { key: 'updatedat', label: this.$t('Update time') },
        { key: 'isEnabled', label: this.$t('Status') },
      ],
      tableHei: document.documentElement.clientHeight*0.7,
      treeHeight: document.documentElement.clientHeight*0.6,
      pageOptions: [15, 30, 50],
      page: null,
      size: 15,
      totalSize: null,
      totalPage: null,
      isOk: false,
      isBusy: true,
    }
  },
  computed: {
    style1() {
      return {
        '--height': this.cardHei + 'px'
      }
    },
    style2() {
      return {
        '--height': this.tableHei + 'px'
      }
    },
    style3() {
      return {
        '--tree-height': this.treeHeight + 'px'
      }
    }
  },
  watch: {
    windowHeight(val) {
      this.cardHei = val *0.82
      this.tableHei = val *0.72
      this.treeHeight = val*0.6
    },
    'codeName'(val) {
      this.getTreeList(val.trim())
      this.condition.codeName = val
      this.getList()
    }
  },
  created() {
    // 初始值
		this.startId = this.NODE_ID_START
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        this.windowHeight = window.fullHeight
      })()
    }
    this.getList()
    this.getTreeList(this.codeName)
  },
  methods: {
    async insertEvent (row) {
      this.dateConfig.dateFormat= 'Y-m-d H:i:ss'
      const $table = this.$refs.xTable
      const record = {
        isEnabled: true,
        updatedAt: new Date()
      }
      const { row: newRow } = await $table.insertAt(record, row)
      await $table.setActiveCell(newRow)
    },
    removeEvent (row) {
      this.box = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete .', {
        title: 'Handle',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        if (value) {
          this.box = value
          this.$refs.xTable.remove(row)
          this.$http.delete(`/masterData/delete/${row.id}`).then(res=>{
            if (res.data.code === '200') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Delete Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.getTreeList()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            }
          })
        }
      })
    },
    selectChangeEvent ({ records }) {},
    allSelect({ records }) {},
    // 首次查询
    getList() {
      this.$http.post('/masterData/search', this.condition).then(res => {
        this.dateConfig.dateFormat= 'Y-m-d H:i:s'
        const info = res.data.data
        this.items = info.content
        this.page = info.page
        this.size = info.size
        this.totalSize = info.totalSize
        this.totalPage = info.totalPage
        this.isBusy = false
        // console.log(info)
      }).catch(error => console.log(error))
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    getTreeList(codeName) {
      this.$http.get(`/masterData/getTreeList?codeName=${codeName}`).then(res => {
        this.treeList = res.data.data
      })
    },
    // add
    validationForm(_node) {
      this.$refs.rules.validate().then(success => {
        if (success) {
          // if(!_node.expanded){
          //   _node.expanded = true
          // }
          // _node.parent.expanded = true
          // console.log('2', _node.parent.data.codeName)
          // console.log('3', _node)
          this.addSubmit(_node)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    // edit
    validationForm1() {
      this.$refs.rules1.validate().then(success => {
        if (success) {
          this.editSubmit()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please check the data',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          return false
        }
        return true
      })
    },
    searchById() {
      this.condition.dataType = this.condition.dataType.trim()
      this.getList()
    },
    handleChangePage(page) {
      this.condition.page = page
      this.getList()
    },
    handlePageChange(active) {
      this.condition.size = active
      this.getList()
    },
    getOrgId(id) {
      this.condition.organizationId = id.id
    },
    handleDelete(_node, _data){// 删除节点
			// console.log(_node, _data)
			// 判断是否存在子节点
			if(_data.children && _data.children.length !== 0){
				// this.$message.error("此节点有子级，不可删除！")
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '此节点有子级，不可删除！',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
				return false;
			}else{
				// 删除操作
				let DeletOprate = () => {
					this.$nextTick(() => {
						if(this.$refs.SlotTree){
							this.$refs.SlotTree.remove(_data)
							// this.$message.success("删除成功！")
              this.$http.delete(`masterData/delete/${_data.id}`).then(res=>{
                if (res.data.code === '200') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Delete Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  const codeName = ''
                  this.getTreeList(codeName)
                  this.getList()
                  if(!_node.expanded){
                    _node.expanded = true
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.message,
                      icon: 'XIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
						}
					})
				}
				// 二次确认
				let ConfirmFun = () => {
					this.$confirm("是否删除此节点？","提示",{
						confirmButtonText: "确认",
						cancelButtonText: "取消",
						type: "warning"
					}).then(() => {
						DeletOprate()
					}).catch(() => {})
				}

				// 判断是否新增： 新增节点直接删除，已存在的节点要二次确认
				_data[this.NODE_KEY] < this.NODE_ID_START ? DeletOprate() : ConfirmFun()
			}
		},
		handleInput(_node, _data){// 修改节点
			// console.log(_node, _data)
			// 退出编辑状态
			if(_node.isEdit){
				this.$set(_node, 'isEdit', false)
			}
		},
		handleEdit(_node, _data){// 编辑节点
			// console.log(_node, _data)
			// // 设置编辑状态
			// if(!_node.isEdit){
			// 	this.$set(_node, 'isEdit', true)
			// }

			// // 输入框聚焦
			// this.$nextTick(() => {
			// 	if(this.$refs['slotTreeInput'+_data[this.NODE_KEY]]){
			// 		this.$refs['slotTreeInput'+_data[this.NODE_KEY]].$refs.input.focus()
			// 	}
			// })
      // 显示弹窗
      this.showEditModal = true
      this.$http.get(`/masterData/getInfoById/${_data.id}`).then(res => {
        this.editInfo = res.data.data
      })
		},
		handleAdd(_node, _data){// 新增节点
      // console.log(_node, _data)
      if (_data.codeCategory === 'data') {
        this.isOk = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'data 类型 不可添加子节点！',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.isOk = false
      }
      this.addInfo.codeCategory = ''
      this.addInfo.codeName = ''
      this.addInfo.codeDescription = ''
			// 判断层级
			if(_node.level >= this.MAX_LEVEL){
				// this.$message.warning("当前已达到"+ this.MAX_LEVEL + "级，无法新增！")
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '当前已达到'+ this.MAX_LEVEL + '级，无法新增！',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
				return false
			}

      // 显示弹窗
      this.showAddModal = true
      this.addInfo.parentCode = _data.id
			// // 参数修改
			// let obj = JSON.parse(JSON.stringify(this.initParam));// copy参数
			// obj.pid = _data[this.NODE_KEY];// 父id
			// obj[this.NODE_KEY] = --this.startId;// 节点id：逐次递减id

			// // 判断字段是否存在
			// if(!_data.children){
			// 	this.$set(_data, 'children', [])
			// }
			// // 新增数据
			// _data.children.push(obj)

      // 展开节点
      _node.expanded = true
			if(!_node.expanded){
				_node.expanded = true
      }
      // console.log('1', _node.data.codeName)
		},
		handleAddTop(_node){// 添加顶部节点
      // console.log(_node)
      this.addInfo.codeCategory = ''
      this.addInfo.codeName = ''
      this.addInfo.codeDescription = ''
      // this.addInfo.parentCode = ''
      delete this.addInfo.parentCode
      this.showAddTopModal = true
			// let obj = JSON.parse(JSON.stringify(this.initParam));// copy参数
			// obj[this.NODE_KEY] = --this.startId;// 节点id：逐次递减id
			// // this.setTree.push(obj)
      // this.treeList.push(obj)
		},
    selCategory(d) {
      this.addInfo.codeCategory = d
      this.editInfo.codeCategory = d
      // if (d === 'data') {
      //   this.isOk = true
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: 'data 类型 不可添加子节点！',
      //       icon: 'AlertCircleIcon',
      //       variant: 'warning',
      //     },
      //   })
      // } else {
      //   this.isOk = false
      // }
    },
    selCategory1(d) {
      this.addInfo.codeCategory = d
      this.editInfo.codeCategory = d
    },
    addSubmit(_node) {
      this.$http.post('/masterData/add', this.addInfo).then(res=>{
        if (res.data.code === '200'){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Add node success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          // console.log(_node.expanded)
          // console.log(_node.parent.expanded)
          // _node.expanded = false
          // _node.parent.expanded = false
          // if(!_node.expanded){
          //   _node.parent.expanded = true
          // }
          this.showAddModal = false
          this.showAddTopModal = false
          const codeName = ''
          this.getTreeList(codeName)
          this.getList()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    editSubmit() {
      this.$http.put('/masterData/edit', this.editInfo).then(res=>{
        if (res.data.code === '200'){
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.showEditModal = false
          const codeName = ''
          this.getTreeList(codeName)
          this.getList()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    cancelAdd() {
      this.showAddModal = false
      this.showAddTopModal = false
    },
    cancelEdit() {
      this.showEditModal = false
      this.showAddTopModal = false
    },
    okAdd() {
    }
  },
}
</script>
<style scoped>
.ll-subBtn{
  margin: 10px 0;
  text-align: right;
}
.ll-padding{
  padding-right: 0;
}
.ll-card1{
  height: var(--height);
}
@media (min-width: 2000px) {
  .ll-card1 {
    height: 85vh;
  }
}
.ll-btn{
  position: fixed;
  bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 6rem;
  width: 100%;
}
.ll-btnS{
  margin-right: 20px;
}
.ll-colBox{
  margin: auto;
}
.ll-outline{
  outline: none;
}
.ll-searchBtn div{
  background-color: #ff9f43;
  color: #fff;
  width: 60px;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.ll-box{
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;
}
.ll-flatPickr{
  cursor: no-drop;
}
.ll-addTopBtn{
  margin-bottom: 1rem;
}
.ll-tableHei{
  height: var(--height);
  max-height: unset;
}
@media (min-width: 2000px) {
  .ll-tableHei{
    height: 75vh;
  }
}
.ll-p{
  align-items: center;
}
.ll-tree{
  height: var(--tree-height);
  overflow-x: auto;
}
</style>
<style>
[dir] .vgt-table.bordered th{
  padding: 10px;
  font-size: 12px;
}
table.vgt-table{
  font-size: 12px;
}
[dir=ltr] .b-sidebar.b-sidebar-right > .b-sidebar-header .close{
  margin-left: unset;
  margin-right: unset;
  font-size: 20px;
}
[dir=ltr] .b-sidebar.b-sidebar-right{
  width: 25%;
}
[dir] .page-item.active{
  height: 27px;
}
.table thead th, .table tfoot th {
  text-transform: capitalize;
}
.el-tree-node__content{
  height: 3rem;
}
#modal-add___BV_modal_outer_:not(:last-child){
  display: none;
}
</style>
<style lang="scss">
// 显示按钮
.show-btns{
  opacity: 1;
}
.comp-tree{
  width: 100%;
  // max-width: 700px;
  // max-height: 80vh;
  // padding: 2em;
  // overflow: auto;
  // 顶部按钮
  .comp-tr-top{
    width: 100px;
    margin-bottom: 2em;
  }
  // 自定义节点
  .comp-tr-node{
    // label
    .comp-tr-node--name{
      display: inline-block;
      line-height: 40px;
      min-height: 40px;
      // 新增
      &.is-new{
        font-weight: bold;
      }
    }
    // button
    .comp-tr-node--btns{
      margin-left: 10px;
      opacity: 0;
      transition: opacity .1s;
      .el-button{
        transform: scale(0.8);// 缩小按钮
        & + .el-button{
          margin-left: -1px;
        }
      }
    }
  }
  // 高亮显示按钮
  .is-current{
    &>.el-tree-node__content{
      .comp-tr-node--btns{
        @extend .show-btns;
      }
    }
  }
  // 悬浮显示按钮
  .el-tree-node__content{
    &:hover{
      .comp-tr-node--btns{
        @extend .show-btns;
      }
    }
  }
}
</style>

